<template>  
<el-row>
  <el-col :span="24" class="banner">
    <div class="banner_title">   2022年广东省<br> <strong> 研究生学术论坛</strong> </div> 
    <div class="banner_t">  “粤铭激光”杯机械工程分论坛 </div>
    <div class="banner_addres">    {{row.m_address}} <span class="banner_addres_p"> |</span>  {{row.m_time}}  </div>
    <ul>
        <li>主办方：{{row.m_sponsor}}</li>
        <li>承办方：{{row.m_cosponsor}}</li>
        <li>协办方：{{row.m_undertaker}}</li> 
    </ul> 
 </el-col>
   <el-col :span="24" class="main"> 
        <div class="meeting_time"><b>  距离会议开始还有：</b><countDown></countDown> </div>
        <div class="content">
            <div class="text">  
                <h1>{{row.m_title}}</h1>
                <span v-html="row.m_content"></span> 
            </div>
        </div>
   </el-col>
</el-row>

</template> 
<script>

import countDown from '../components/CountDown';
import {getMeetingRow} from '../api/index'; 

 export default {
    components: {
        countDown         
    },
  name: 'IndexVue',
  data() {
        return { 
            row:{
                m_address:'',
                m_time:'',
                m_title:''
            }, 
        }
    },  
    methods: {
       go(path){  
            this.$router.push({
            path:path,
            query:{
                   
                },
            });
       }
         
    },
   async created(){
    
        let m_id = this.$route.query.m_id; 
        if(m_id==undefined) m_id = this.$store.getters.getMid //空m_id
       
        const res =  await getMeetingRow({'m_id':m_id});
            this.row = res.data.result;  
            this.$store.commit("setMid",this.row.m_id) //m_id 存起来   
 
        // console.log(localStorage.getItem("name")) 
        document.title = this.row.m_title
    }
  
}
</script>

<style scoped>  
.banner{ 
    background: url('../../public/static/images/banner.png'); 
    width: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height:288px;
    /* border: solid 1px red; */
}
 .banner_title{
    background-image: url('../../public/static/images/banner_title.png') ;
    background-repeat: no-repeat;
    width: 137px;
    height: 63px;  
    padding-top: 5px;
    background-size: 100%;
    margin: 5px auto;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
}
.banner_t{
    width: 100%;
    line-height: 30px; 
    color: #225AC9;
    font-size: 22px;
    font-weight: bolder;
    text-align: center;
}
.banner_addres{
    background-image: url('../../public/static/images/banner_bg.png');
    width: 50%; 
    margin: 5px auto; 
    font-size: 14px;
    color: #ffffff;
    background-repeat: no-repeat;
    line-height: 20px;
    background-size: 100% 100%;
    text-align: center; 
}
ul{
    padding: 0;
    margin: 0;
    margin-top: 10px;
}
 .banner ul li{
    width: 90%;;
    list-style: none; 
    margin: 0 auto;
    line-height: 20px;
    font-size: 12px;
    font-weight: bolder;
    text-align: center;
    color: #4F5457;
}
.main{
    background: #F8F8F8;
    min-height: 300px;
    margin-bottom: 100px;
}
.meeting_time{
    width: 80%;
    height:60px;
    background: url('../../public/static/images/meeting_bg.png'); 
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    font-size: 12px;
    text-align: center;
    line-height: 50px;
    position: relative;
    top: -15px;
    color: #F8F8F8;
    font-weight: bold;
}
.content{
    width: 90%;
    min-height: 300px;
    background: #FBF8FB;
    margin: 0 auto;
    font-size: 12px;
    text-align: left;
    padding: 10px; 
    margin-top: -20px;
}
.content h1{
    padding-top: 0px;
    line-height: 28px;
    color: #366CD7;
    text-align: center;
    font-size: 14px; 
}
.content p{ 
    line-height: 22px; 
}
</style>
