/****   request.js   ****/
import axios from 'axios' 
// import qs from 'qs'  
// import { Dialog } from 'vant'; 
import router from '../router'

// const {  baseUrl } = require("./env.js").prod // 选取环境
	const service = axios.create({
		// baseURL: "/api", // 公共接口-
		baseURL: "/",
		// baseURL: 'http://bbs.gd58hr.com/', 
		withCredentials: true,
		timeout: 10 * 1000 // 超时时间 单位是ms，这里设置了3s的超时时间
	})

	// 请求拦截器
	service.interceptors.request.use(
		config => {   
			// config.data = qs.stringify(config.data)
			config.headers = {
				'Content-Type': 'application/json; charset=utf-8' ,//配置请求头 
			}  
			// content-type:application/json// application/x-www-form-urlencoded
			if(config.token==true) config.headers['Authorization'] = localStorage.token; 
			// console.log(config);
			return config
		}, 
		error => {
			Promise.reject(error)
		}
	) 
	// 响应拦截器
	service.interceptors.response.use(
		response => {  
			if(response.data==400){
				// alert("登录超时")
				localStorage.clear();
				// Dialog.alert({ message: '登录过期!' })
				router.push({ path: '/login' });
			}   
			return response //接收到响应数据并成功后的一些共有的处理 
		}, 
		error => {/***** 接收到异常响应的处理开始 *****/ 
			if (JSON.stringify(error).includes('timeout')) {// 超时处理
				console.log('服务器响应超时，请刷新当前页')
			}
			console.log(error.message)   
			return Promise.resolve(error.response)/***** 处理结束 *****/
		}) 

export default service  
