<template>
<div class="main"> 
    <el-row>
        <el-col :span="24">
            <div class="login"> 
                <h1>登录 </h1> 
                <el-form
                    status-icon
                    :model="user"
                    :rules="formRules"
                    :hide-required-asterisk="true"
                    ref="user" 
                    > 
                    <div class="phone">
                        <el-form-item label="手机" prop="phone"> 
                            <el-input type="text"  v-model.trim="user.phone" placeholder="请输入手机号码" class="phone_in"></el-input> 
                        </el-form-item>
                    </div> 
                    <div class="code"> 
                        <el-form-item label="验证码" prop="code" style="float:left;">
                            <el-input   v-model.trim="user.code" placeholder="验证码" class="code_in"></el-input>
                            <el-button 
                            type="info" 
                            :disabled="codeCountdown>0"
                            v-html="codeBtnText"
                            @click="changeRestart"
                            class="bt_code"
                            >
                        </el-button> 
                        </el-form-item>  
                        
                    </div>
                    <div class="submit">
                        <el-button @click="submitChange('user')" type="primary" class="sub_bt">登录</el-button>
                    </div>
                </el-form>
            </div>
        </el-col>
    </el-row>
     
</div>
</template>

<script>  
import  {Key,Phone}  from "@element-plus/icons-vue"; 

import { regEx } from '../utils/regEx'
import { phonecode, CheckCode }  from '../api/index';  
import { getCode } from '../mixins/getCode'
const validateTel = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号码'))
  } else if (!regEx.mobile.test(value)) {
    callback(new Error('手机号码格式不正确'))
  } else {
    callback()
  }
}

export default {
    components: { 
        Key,
        Phone
    },
    name: 'Login',
    mixins: [getCode],
    data() {
        return { 
            form: {
                code: ''
            },
            user: {
                phone: '',
                code: ''
            },
            formRules: {
                phone: { required: true, validator: validateTel, trigger: 'blur' },
                code: { required: true, message: '请输入验证码', trigger: 'blur' }
            }
        }
    },
    methods: {
        async restart () {
            try {
                if (this.codeCountdown <= 0) { 
                    const res = await phonecode({phone:this.user.phone})
                    if (res.data.code === 10000 && res.data.result == true) {
                        this.getCode()
                    }else{
                        this.$message.success({
                            showClose: true,
                            message: '发送短信失败!',
                            type: 'error'
                        }) 
                    }
                }
            } catch (e) {
                console.log(e)
            }
        },
        async changeRestart () { //获取验证码
            try {
                if (this.codeCountdown <= 0) {
                    if (!this.user.phone) {
                        this.$refs.user.validateField('phone')
                        return false
                    } 
                    const res = await phonecode({phone:this.user.phone})
                    if (res.data.code === 10000 && res.data.result == true) {
                        this.getCode()
                    }else{
                        this.$message.success({
                            showClose: true,
                            message: '发送短信失败!',
                            type: 'error'
                        }) 
                    }
                }
            } catch (e) {
                console.log(e)
            }
        },
        submitChange (formName) {//登录
            try { 
                if(this.$store.getters.getMid ==''){ //没有会议
                    this.$router.push({
                        path: '/?m_id='+this.$store.getters.getMid,
                    });
                } 
                this.$refs[formName].validate(async (valid) => { 
                    if (valid) {
                        const res = await CheckCode({
                                phone:this.user.phone,
                                code:this.user.code,
                                m_id:this.$store.getters.getMid
                            })

                        // let res ={
                        //         data:{
                        //             "code":10000,
                        //             "message":"ok",
                        //             "result":{"bool":true,
                        //             "info":{"u_id":"","name":"","phone":"","company":"","remarks":"","m_id":""}},
                        //             "requestMethod":""
                        //            }
                        //         }
                        
                        if (res.data.code === 10000 && res.data.result.bool == true) {  
                            this.$store.commit("setPhone",this.user.phone) //phone 存起来 
                            this.$store.commit("setUid",res.data.result.info.u_id) //u_id 存起来 
                            this.$store.commit("setShow",true) //show 显示退出
                            this.$store.commit("setDialogVisible",false) //退出 Dialog

                            this.$message.success({
                                showClose: true,
                                message: '登录成功!',
                                type: 'success'
                            })  
                            // console.log(res.data.result)
                            this.$router.push({
                                path: '/?m_id='+this.$store.getters.getMid,
                            });
                        }else{
                            this.$message.success({
                                showClose: true,
                                message: '登录失败!',
                                type: 'error'
                            })  
                        } 
                        // console.log(res.data.result)
                    }  
                    console.log('error submit!!')
                    return false 
                })
            } catch (e) {
                console.log(e)
            }
        }
    }

}


</script>
<style scoped> 
.main{
    /* width: 1100px; */
    min-height: 250px;
    margin: 0 auto;
    background: #fff;
}
.main .login{
    /* padding: 0px 0px 20px 30px; */
    width:100%;
    /* border:solid 1px #467DEB; */
    height: 250px;
    border-radius: 5px; 
    margin-top: -50px ;
    padding: 0;
}
.main h1{
    text-align: center;
}
.main .phone .code{
    width: 100%;
    height: 65px;
    font-size: 24px;
    line-height: 50px;
}
.phone .phone_in{
    height:50px;
    width: 90%; 
}
.login >>> .el-form-item__label {
  font-size: 18px;
  line-height:48px;
}
 
.code .code_in{
    height:50px;
    width: 40%; 
}
.code .bt_code{
    height:50px;
    width: 40%; 
    /* margin-top: -108px; */
    background: #57565f;
    color: #fff;
    margin-left: 10px;
}
.submit{
    width: 100%;
    float: left;
    text-align: center;
}
.submit .sub_bt{
    width: 60%;
    text-align: center;
    height: 40px;
    margin-top: 36px;
    height:50px;
    font-size: 26px;
}
 

</style>