<template> 


   <el-upload
     style="width:200px;height:100px;border:solid 1px red;margin:30px auto; line-height:20px;"
    :action="url"
    :on-preview="handlePreview"
    :on-remove="handleRemove"
    :before-remove="beforeRemove"
    name="fileupload"
    multiple
    :limit="2"
    :on-exceed="handleExceed"
    :file-list="fileList">

        <el-button size="small" type="primary">点击上传</el-button>
 
    </el-upload>

</template>
    
<script>
 
export default {
    name: 'Test',
    data() {
      return {
        url:'/api/api/SellerMeeting/meeting_data_upload',
        fileList: [
                {name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, 
                {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}
                ]
      };
    },
  methods: {
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`);
      }
  }
};
</script>    
