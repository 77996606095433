<template>
<el-row>
  <el-col :span="24" style="background: #F7F6F6;">
    <div class="main">
        <div class="title">会议动态 </div>
            <div class="content"> 
                <div class="text">  
                    <h1>{{row.n_title}}</h1>
                    <div class="date"> 发布日期：{{this.format(row.update_time)}}</div>
                    <span v-html="row.n_content"></span> 
                </div>   
            </div> 
       
    </div>
    </el-col>
</el-row>
</template> 

<script>
 import {getNewsRow} from '../api/index';
export default {
  name: 'DataVue',
   data() {
         return {
            row:'', 
        }
    }, 
    methods:{
        format(shijianchuo) 
        { 
            shijianchuo = parseInt(shijianchuo)*1000;
            var time = new Date(shijianchuo); 
            var y = time.getFullYear(); 
            var m = time.getMonth()+1; 
                m = m < 10 ? "0" + m : m
            var d = time.getDate(); 
                d = d < 10 ? "0" + d : d
            var h = time.getHours(); 
                h = h < 10 ? "0" + h : h
            var mm = time.getMinutes(); 
                mm = mm < 10 ? "0" + mm : mm
            var s = time.getSeconds(); 
                s = s < 10 ? "0" + s : s
            return y+'-'+m+'-'+d+' '+h+':'+mm+':'+s; 
        }, 

    },
    created(){
        
        getNewsRow({'n_id':this.$route.query.id}).then( res=>{
            this.row = res.data.result;
            console.log(this.row)
        }) 
    }
}
</script>

<style scoped>
.table{
    width: 80%;
    margin: 0 auto;
}
.main{
    width: 100%;
    min-height: 300px;
    background: #ffffff;
    margin: 0px auto; 
    padding-bottom: 80px;
}
.main .title{
     width: 100%;
    line-height: 30px;
    background: #467DEB;
    text-align: center;
    color:#ffffff;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    font-weight: bolder;
    font-size: 14px;
    margin-bottom: 20px;
}
.main .content{
    padding: 0 10px;
    text-align: left;
    padding-bottom: 10px;
}
 .content h1{
    padding-top: 1px;
    line-height: 50px;
    color: #366CD7;
    text-align: center;
     
}
.content p{ 
    line-height: 28px; 
}
.content .text{
    width: 100%; 
    padding-bottom: 30px;
}
.content .text h1{
   font-size: 14px;
   line-height: 28px;
     
}
.content .text .date{
    width: 90%;
    height: 20px;
    margin: 30 auto;
    text-align: center;
    color:#999999;
    border-bottom: dashed 2px #eeeeee;
   
    /* margin-bottom: 20px; */
    font-size: 12px;

}
.main .content >>>table
{
    border-spacing:0px;
    border-collapse:collapse;
} 

.main .content >>> tr{
    background-color:#f7faff;
}
.main .content >>> tr:nth-child(odd){
    background-color:#fcfcfc;
}
 
</style>
