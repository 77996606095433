 
import request from './request' // 导入封装好的axios实例

// token = true //是否需要token 
const http = {
	/**
	 * methods: 请求
	 * @param url 请求地址 
	 * @param params 请求参数
	 */
	get(url, params, token = true) {
		const config = {
			method: 'get',
			url: url,
			token: token
		}
		if (params) config.params = params
		return request(config)
	},
	post(url, params, token = true) {
		const config = {
			method: 'post',
			url: url,
			token: token
		} 
		if (params) config.data = params
		return request(config)
	},
	put(url, params, token = true) {
		const config = {
			method: 'put',
			url: url,
			token: token
		}
		if (params) config.params = params
		return request(config)
	},
	delete(url, params, token = true) {
		const config = {
			method: 'delete',
			url: url,
			token: token
		}
		if (params) config.params = params
		return request(config)
	}
} 
export default http
