import http from '../http/http' 
 
export const getNewsList = (parem)=>{ return http.post(`/api/SellerMeeting/get_meeting_news`,parem) }//会议动态列表
export const getNewsRow = (parem)=>{ return http.get(`/api/SellerMeeting/get_meeting_news_row`,parem) } //会议动态内容
export const getMeetingRow = (parem)=>{ return http.get(`/api/SellerMeeting/get_meeting_row`,parem) } //会议内容
export const meetingParticipantsSave = (parem)=>{ return http.post(`/api/SellerMeeting/meeting_participants_save`,parem) } //参会报名
export const getMeetingParticipantsOne = (parem)=>{ return http.post(`/api/SellerMeeting/get_meeting_participants_one`,parem) } //参会报名者信息

export const meetingSubmissionSave = (parem)=>{ return http.post(`/api/SellerMeeting/meeting_submission_save`,parem) } //投稿
export const getMeetingSubmissionOne = (parem)=>{ return http.post(`/api/SellerMeeting/get_meeting_submission_one`,parem) } //投稿信息

export const phonecode = (parem)=>{ return http.post(`/api/SellerMeeting/phone_code`,parem) } //发送验证码
export const CheckCode2 = (parem)=>{ return http.post(`/api/SellerMeeting/check_code2`,parem) } //检查验证码登录
export const CheckCode = (parem)=>{ return http.post(`/api/SellerMeeting/check_code`,parem) } //检查验证码登录

export const getMeetingData = (parem)=>{ return http.post(`/api/SellerMeeting/get_meeting_data`,parem) } //资料下载
export const meetingDataUpload = (parem)=>{ return http.post(`/api/SellerMeeting/meeting_data_upload`,parem) } //论文上传

export const meetingUploadDel = (parem)=>{ return http.post(`/api/SellerMeeting/meeting_upload_del`,parem) } //论文删除





