<template> 
 
    <div class="meeting_msg">
        <span>[参会信息]</span> 
        <p class="msg_title">会议名称：{{row.m_title}}</p>
        <p class="time">距离报名截止还有：<countDown></countDown></p>
    </div> 
  
</template>

<script>
import countDown from '../components/CountDown';
import {getMeetingRow} from '../api/index';
import {useStore} from "vuex";

export default {
    components: {
        countDown         
    },
  name: 'MeetingInfoVue',
   data() {
      return { 
        row:'', 
      };
    }, 
    methods:{
        
    },
    created(){
        const {state,commit} = useStore()
        
        let m_id = this.$route.query.m_id; 
        if(m_id==undefined) m_id = state.m_id //空m_id 

        getMeetingRow({'m_id':m_id}).then( res=>{
            this.row = res.data.result;   
            commit("setMid",this.row.m_id) //m_id 存起来  
        }) 
    }
}
</script> 
 
<style scoped> 
  
.meeting_msg{
    padding: 0 30px;
    text-align: left;
}
.meeting_msg span{
    font-weight: bolder;
    color: #366CD7; 
}
.meeting_msg .time{
    color: #FF6600;
}  
.meeting_msg p{
    margin:0px;
    line-height: 32px;
    font-size: 14px;
}
.meeting_msg .msg_title{
    margin-top:20px;
}

</style>
