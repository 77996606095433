// getCode.js
export const getCode = {
    data () {
      return {
        codeCountdown: 0,
        timer: null
      }
    },
    computed: {
      codeBtnText () {
        return this.codeCountdown > 0
          ? `<span class="g-c-42b">${this.codeCountdown}S</span>后获取` : '获取验证码'
      }
    },
    methods: {
      async getCode () {
        if (this.codeCountdown > 0) {
          return
        }
        this.codeCountdown = 60
        this.timer = setInterval(() => {
          this.codeCountdown = Math.max(0, this.codeCountdown - 1)
          if (this.codeCountdown <= 0) {
            clearInterval(this.timer)
          }
        }, 1000)
      }
    }
  }
  
  