<template>  
<div style="position: relative;">  
  <div class="outs" v-if="show">
    <el-link :underline="false"  @click="out"  class="login_out"><b style="color:#999" >退出登录</b></el-link>  
  </div> 
  <div class="mains">  
      <el-dialog  
      v-model="dialogVisible"  
      center
      width="95%"
      :show-close = "showClose"
      :before-close="handleClose"
    >  
      <LoginVue></LoginVue> 
    </el-dialog>  
    <router-view></router-view>  
    <Footer  :show="show" :column="column" />
  </div>
</div>
</template>

<script>
import Footer from './components/Footer.vue' 
import LoginVue from './view/Login.vue'
import {getMeetingRow} from './api/index'; 
export default {
  name: 'App',
  components: {
    Footer,
    LoginVue
  },
  data() {
        return {  
            // dialogVisible:this.$store.state.dialogVisible,
            showClose:false,
            headerData: [
                {
                    name: '会议主页', 
                    icon:1,
                    path: '/'
                }, {
                    name: '参会报名', 
                     icon:2,
                    path: '/applicants'
                }, {
                    name: '论文投稿', 
                     icon:3,
                    path: '/contribution'
                }, {
                    name: '会议动态', 
                     icon:4,
                    path: '/news'
                }, 
                {
                    name: '会议资料', 
                    icon:5,
                    path: '/data'
                } 
            ], 
        }
  },
   computed:{
    show(){   
      // return  this.$store.getters.getShow 
      return  this.$store.state.show;  
    },
    column(){
      let column =  this.$route.path;
      if(column=='/content') column='/news' 
      return  column;
    },
    dialogVisible(){

      // let visible =  this.$store.state.dialogVisible
      // this.$store.commit("setDialogVisible",false) 
      
      // return  visible;
      return  this.$store.state.dialogVisible

    }

  },
   methods:{
      getvisible(){
        let visible =  this.$store.state.dialogVisible
        this.$store.commit("setDialogVisible",false) 
        
        return  visible;
      },
      handleClose(){
         this.$store.commit("setDialogVisible",false) 
      },
      out(){
          this.$store.commit('setPhone',''); 
          this.$store.commit('setUid',''); 
          this.$store.commit("setShow",false) 
          localStorage.clear();
          this.$message.success({
                              showClose: true,
                              message: '退出成功!', 
                          }) 
          this.$router.push({ path: '/?m_id='+this.$store.getters.getMid, }); 
          
      }
  }, 
    async created(){
    
        let m_id = this.$route.query.m_id; 
        if(m_id==undefined) m_id = this.$store.getters.getMid //空m_id 
        const res =  await getMeetingRow({'m_id':m_id});
          let   row = res.data.result;   
         
        document.title =  row.m_title
    }
}
</script>

<style>
  
 body{
  position: relative;
  padding: 0;margin: 0;
 }
 a{
  text-decoration: none;
}
li{
  list-style: none;
}
div,body,li,ul{
  margin:0;
  padding:0;
}
.router-link-active {
    text-decoration: none; 
    color:#000000;
  }
  a{
    text-decoration: none;
     color:#000000;
  }
.login_out{  
  z-index: 100000;
  width: 100%;
  text-align: right;
  line-height: 30px;
  float: right;
  height: 30px;
  color: chocolate;
}
.mains{
  width: 100%;
  float: left;  
  background: #fff;
}
.outs{
  width: 20%;  
  float: left;
  position: absolute;
  background: #fff;
  text-align: right;
  right: 0;
}
</style>
