<template>
<el-row>
  <el-col :span="24" style="background: #F7F6F6;">
    <div class="main">
        <div class="title">参会报名 </div>
            <div class="content">
                <MeetingInfoVue></MeetingInfoVue>
            <div class="man">参会人 </div>
            <div class="form_box">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  >  
                <el-row>
                    <el-col :span="22" :offset="1"> 
                        <el-form-item label="姓名" prop="name"> 
                            <el-input placeholder="请输入姓名" v-model="ruleForm.name"></el-input> 
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1"> </el-col>
                    <el-col :span="22" :offset="1"> 
                            <el-form-item label="手机" prop="phone">
                                <span>{{ruleForm.phone}}</span>
                                <input type="hidden" name="phone" v-model="ruleForm.phone">
                               
                            </el-form-item>
                    </el-col>
                    <el-col :span="8" :offset="1"> </el-col>
                   <el-col :span="22" :offset="1"> 
                            <el-form-item label="单位" prop="company">
                                <el-input placeholder="请输入工作单位" v-model="ruleForm.company"></el-input>
                            </el-form-item>
                    </el-col>
                    <el-col :span="8" :offset="1"> </el-col>
                   <el-col :span="22" :offset="1"> 
                            <el-form-item label=" 备注" prop="remarks">
                                <el-input type="textarea" :rows="5" placeholder="请输入备注" v-model="ruleForm.remarks"></el-input>
                            </el-form-item>
                    </el-col>
                    <el-col :span="20" :offset="2"> 
                            <el-form-item> 
                                <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                                <el-button class="bt" type="primary" @click="submitForm('ruleForm')">提交</el-button>
                            </el-form-item>
                    </el-col>  
                </el-row>
                </el-form>
            </div>
        </div>
       
    </div>
    </el-col>
</el-row> 
</template>

<script> 
import MeetingInfoVue from '../components/MeetingInfo';
import {meetingParticipantsSave,getMeetingParticipantsOne} from '../api/index'; 
 
export default {
    components: { 
        MeetingInfoVue, 
    },
   name: 'ApplicantsVue',
   data() {
      return {  
        dialogVisible: true,
        ruleForm: {
          u_id:'',
          name: '',
          phone: '',
          company: '', 
          m_id:'',
        },
        rules: {
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { min: 2, max: 5, message: '请正确输入姓名', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { min: 11, max: 11, message: '请正确输入手机号', trigger: 'blur' }
          ],
          company: [
            {required: true, message: '请输入工作单位', trigger: 'blur' },
            { min: 2, max: 50, message: '请正确输入工作单位', trigger: 'blur' }
          ] 
        }
      };
    },
    methods:{
        submitForm(formName) {  
            this.$refs[formName].validate((valid) => { 
                if (valid) { 
                    meetingParticipantsSave(this.ruleForm).then(res=>{ 
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        this.$store.commit("setUid", res.data.result)
                        console.log(res.data)
                        location.reload()
                    }) 
                }
                return false;  
            }); 
        },  
       

    },
    created(){
     
        getMeetingParticipantsOne({//检查是否登录
            'm_id':this.$store.getters.getMid,
            'phone':this.$store.getters.getPhone
        }).then(res=>{
            this.ruleForm = res.data.result 
            this.ruleForm.m_id = this.$store.getters.getMid; 
            this.ruleForm.phone = this.$store.getters.getPhone
            this.$store.commit("setUid", this.ruleForm.u_id)
            // console.log(this.ruleForm)
        }) 

          
    }
}
</script> 
 
<style scoped> 
.main{
    width: 100%;
    min-height: 300px;
    background: #ffffff;
    margin: 0px auto; 
    padding-bottom: 100px;
}
.main .title{
    width: 100%;
    line-height: 30px;
    background: #467DEB;
    text-align: center;
    color:#ffffff;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    font-weight: bolder;
    font-size: 14px;
    margin-bottom: 20px;
}
.main .content{
    padding: 0 5px;
}
.main .meeting_msg{
    padding: 0 10px;
    text-align: left;
}
.meeting_msg span{
    font-weight: bolder;
    color: #366CD7; 
}
.meeting_msg .time{
    color: #FF6600;
}  
.meeting_msg p{
    margin:0px;
    line-height: 32px;
    font-size: 14px;
}
.meeting_msg .msg_title{
    margin-top:15px;
}
.man{
    font-weight: bolder;
    color: #366CD7; 
    text-align: left;
    padding: 0 10px;
    margin-top: 20px;
}
.form_box{
    border: solid 1px #E5E5E5;
    padding: 15px 3px 5px 0px;
    width: 95%;
    min-height: 280px;
    margin: 0 auto;
    margin-top:15px; 
}
.bt{
    width:60%;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    font-weight: bolder;
    line-height: 28px;
    padding-bottom: 10px;
}
</style>
