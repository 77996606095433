<template>
<div class="footer">
  <ul>
    
      <!-- <li  v-for="val in headerData" :key="val.path" > 
        <router-link :to=val.path  > 
          <p><img :src="val.icon"></p>
          <span :class="column==val.path?'current':''"> {{val.name}}</span>
        </router-link>
      </li> -->
    <router-link to='/'   >
      <li>
        <p><img src="../../public/static/images/icon1.png" alt=""></p> 
        <span :class="column=='/'?'current':''">会议主页</span>
      </li>
    </router-link>
    <router-link to='/applicants'>
      <li>
        <p><img src="../../public/static/images/icon2.png" alt=""></p> 
        <span :class="column=='/applicants'?'current':''">参会报名</span>
      </li>
    </router-link>
    <router-link to='/contribution'>
      <li>
        <p><img src="../../public/static/images/icon3.png" alt=""></p> 
        <span :class="column=='/contribution'?'current':''">论文投稿</span>
      </li>
    </router-link>
     <router-link to='/news'>
      <li>
        <p><img src="../../public/static/images/icon4.png" alt=""></p> 
        <span :class="column=='/news'?'current':''">会议动态</span>
      </li>
    </router-link>
     <router-link to='/data'>
      <li>
        <p><img src="../../public/static/images/icon5.png" alt=""></p> 
        <span :class="column=='/data'?'current':''">会议资料</span>
      </li> 
    </router-link>
  </ul>
</div>

</template> 
<script> 
export default {
    name: 'FooterVue', 
    props:{ 
        show:{
           type: Boolean,	
            default () {
                return false;
            },
        },
        column:{
            type: String,	
            default () {
                return '/';
            },
        }
   },
  data(){
      return { 
         headerData: [
                {
                    name: '会议主页', 
                    icon: "../../public/static/images/icon1.png",
                    path: '/'
                }, {
                    name: '参会报名', 
                     icon:  "../../public/static/images/icon2.png",
                    path: '/applicants'
                }, {
                    name: '论文投稿', 
                     icon: "../../public/static/images/icon3.png",
                    path: '/contribution'
                }, {
                    name: '会议动态', 
                     icon: "../../public/static/images/icon4.png",
                    path: '/news'
                }, 
                {
                    name: '会议资料', 
                    icon: "../../public/static/images/icon5.png",
                    path: '/data'
                } 
            ], 
      }
  },
  methods:{ 
    out(){
        this.$store.commit('setPhone',''); 
        this.$store.commit('setUid',''); 
        this.$store.commit("setShow",false) 

        this.$message.success({
                            showClose: true,
                            message: '退出成功!', 
                        }) 
        this.$router.push({ path: '/?m_id='+this.$store.getters.getMid, }); 
        
    }

  },
}
</script>

<style scoped> 
  *{
    padding: 0;
    margin: 0;
  }
  .footer{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 90px;  
    border-top: solid 2px #FBF8FB;
    background: #ffffff;
  }
  .footer ul{
    display: flex;
    flex-wrap:nowrap;
    justify-content:space-around;
    align-items:flex-end;
    height: 80px;
  } 
  .footer ul li {  
    list-style: none; 
    font-size: 14px;
    font-weight: bolder;
    text-align: center;  
    line-height: 24px;
    color: #95969A;
  }
  .current{
    color: #4B99DC;
  }

</style>
